const countries = {
  en: [
    {
      name: 'Afghanistan',
      code: '+93',
      flag: 'https://flagsapi.com/AF/flat/32.png',
      index: 0,
    },
    {
      name: 'Albania',
      code: '+355',
      flag: 'https://flagsapi.com/AL/flat/32.png',
      index: 1,
    },
    {
      name: 'Algeria',
      code: '+213',
      flag: 'https://flagsapi.com/DZ/flat/32.png',
      index: 2,
    },
    {
      name: 'American Samoa',
      code: '+1-684',
      flag: 'https://flagsapi.com/AS/flat/32.png',
      index: 3,
    },
    {
      name: 'Andorra',
      code: '+376',
      flag: 'https://flagsapi.com/AD/flat/32.png',
      index: 4,
    },
    {
      name: 'Angola',
      code: '+244',
      flag: 'https://flagsapi.com/AO/flat/32.png',
      index: 5,
    },
    {
      name: 'Anguilla',
      code: '+1-264',
      flag: 'https://flagsapi.com/AI/flat/32.png',
      index: 6,
    },
    {
      name: 'Antarctica',
      code: '+672',
      flag: 'https://flagsapi.com/AQ/flat/32.png',
      index: 7,
    },
    {
      name: 'Antigua and Barbuda',
      code: '+1-268',
      flag: 'https://flagsapi.com/AG/flat/32.png',
      index: 8,
    },
    {
      name: 'Argentina',
      code: '+54',
      flag: 'https://flagsapi.com/AR/flat/32.png',
      index: 9,
    },
    {
      name: 'Armenia',
      code: '+374',
      flag: 'https://flagsapi.com/AM/flat/32.png',
      index: 10,
    },
    {
      name: 'Aruba',
      code: '+297',
      flag: 'https://flagsapi.com/AW/flat/32.png',
      index: 11,
    },
    {
      name: 'Australia',
      code: '+61',
      flag: 'https://flagsapi.com/AU/flat/32.png',
      index: 12,
    },
    {
      name: 'Austria',
      code: '+43',
      flag: 'https://flagsapi.com/AT/flat/32.png',
      index: 13,
    },
    {
      name: 'Azerbaijan',
      code: '+994',
      flag: 'https://flagsapi.com/AZ/flat/32.png',
      index: 14,
    },
    {
      name: 'Bahamas',
      code: '+1-242',
      flag: 'https://flagsapi.com/BS/flat/32.png',
      index: 15,
    },
    {
      name: 'Bahrain',
      code: '+973',
      flag: 'https://flagsapi.com/BH/flat/32.png',
      index: 16,
    },
    {
      name: 'Bangladesh',
      code: '+880',
      flag: 'https://flagsapi.com/BD/flat/32.png',
      index: 17,
    },
    {
      name: 'Barbados',
      code: '+1-246',
      flag: 'https://flagsapi.com/BB/flat/32.png',
      index: 18,
    },
    {
      name: 'Belarus',
      code: '+375',
      flag: 'https://flagsapi.com/BY/flat/32.png',
      index: 19,
    },
    {
      name: 'Belgium',
      code: '+32',
      flag: 'https://flagsapi.com/BE/flat/32.png',
      index: 20,
    },
    {
      name: 'Belize',
      code: '+501',
      flag: 'https://flagsapi.com/BZ/flat/32.png',
      index: 21,
    },
    {
      name: 'Benin',
      code: '+229',
      flag: 'https://flagsapi.com/BJ/flat/32.png',
      index: 22,
    },
    {
      name: 'Bermuda',
      code: '+1-441',
      flag: 'https://flagsapi.com/BM/flat/32.png',
      index: 23,
    },
    {
      name: 'Bhutan',
      code: '+975',
      flag: 'https://flagsapi.com/BT/flat/32.png',
      index: 24,
    },
    {
      name: 'Bolivia',
      code: '+591',
      flag: 'https://flagsapi.com/BO/flat/32.png',
      index: 25,
    },
    {
      name: 'Bosnia and Herzegovina',
      code: '+387',
      flag: 'https://flagsapi.com/BA/flat/32.png',
      index: 26,
    },
    {
      name: 'Botswana',
      code: '+267',
      flag: 'https://flagsapi.com/BW/flat/32.png',
      index: 27,
    },
    {
      name: 'Brazil',
      code: '+55',
      flag: 'https://flagsapi.com/BR/flat/32.png',
      index: 28,
    },
    {
      name: 'British Indian Ocean Territory',
      code: '+246',
      flag: 'https://flagsapi.com/IO/flat/32.png',
      index: 29,
    },
    {
      name: 'British Virgin Islands',
      code: '+1-284',
      flag: 'https://flagsapi.com/VG/flat/32.png',
      index: 30,
    },
    {
      name: 'Brunei',
      code: '+673',
      flag: 'https://flagsapi.com/BN/flat/32.png',
      index: 31,
    },
    {
      name: 'Bulgaria',
      code: '+359',
      flag: 'https://flagsapi.com/BG/flat/32.png',
      index: 32,
    },
    {
      name: 'Burkina Faso',
      code: '+226',
      flag: 'https://flagsapi.com/BF/flat/32.png',
      index: 33,
    },
    {
      name: 'Burundi',
      code: '+257',
      flag: 'https://flagsapi.com/BI/flat/32.png',
      index: 34,
    },
    {
      name: 'Cambodia',
      code: '+855',
      flag: 'https://flagsapi.com/KH/flat/32.png',
      index: 35,
    },
    {
      name: 'Cameroon',
      code: '+237',
      flag: 'https://flagsapi.com/CM/flat/32.png',
      index: 36,
    },
    {
      name: 'Canada',
      code: '+1',
      flag: 'https://flagsapi.com/CA/flat/32.png',
      index: 37,
    },
    {
      name: 'Cape Verde',
      code: '+238',
      flag: 'https://flagsapi.com/CV/flat/32.png',
      index: 38,
    },
    {
      name: 'Cayman Islands',
      code: '+1-345',
      flag: 'https://flagsapi.com/KY/flat/32.png',
      index: 39,
    },
    {
      name: 'Central African Republic',
      code: '+236',
      flag: 'https://flagsapi.com/CF/flat/32.png',
      index: 40,
    },
    {
      name: 'Chad',
      code: '+235',
      flag: 'https://flagsapi.com/TD/flat/32.png',
      index: 41,
    },
    {
      name: 'Chile',
      code: '+56',
      flag: 'https://flagsapi.com/CL/flat/32.png',
      index: 42,
    },
    {
      name: 'China',
      code: '+86',
      flag: 'https://flagsapi.com/CN/flat/32.png',
      index: 43,
    },
    {
      name: 'Christmas Island',
      code: '+61',
      flag: 'https://flagsapi.com/CX/flat/32.png',
      index: 44,
    },
    {
      name: 'Cocos Islands',
      code: '+61',
      flag: 'https://flagsapi.com/CC/flat/32.png',
      index: 45,
    },
    {
      name: 'Colombia',
      code: '+57',
      flag: 'https://flagsapi.com/CO/flat/32.png',
      index: 46,
    },
    {
      name: 'Comoros',
      code: '+269',
      flag: 'https://flagsapi.com/KM/flat/32.png',
      index: 47,
    },
    {
      name: 'Cook Islands',
      code: '+682',
      flag: 'https://flagsapi.com/CK/flat/32.png',
      index: 48,
    },
    {
      name: 'Costa Rica',
      code: '+506',
      flag: 'https://flagsapi.com/CR/flat/32.png',
      index: 49,
    },
    {
      name: 'Croatia',
      code: '+385',
      flag: 'https://flagsapi.com/HR/flat/32.png',
      index: 50,
    },
    {
      name: 'Cuba',
      code: '+53',
      flag: 'https://flagsapi.com/CU/flat/32.png',
      index: 51,
    },
    {
      name: 'Curacao',
      code: '+599',
      flag: 'https://flagsapi.com/CW/flat/32.png',
      index: 52,
    },
    {
      name: 'Cyprus',
      code: '+357',
      flag: 'https://flagsapi.com/CY/flat/32.png',
      index: 53,
    },
    {
      name: 'Czech Republic',
      code: '+420',
      flag: 'https://flagsapi.com/CZ/flat/32.png',
      index: 54,
    },
    {
      name: 'Democratic Republic of the Congo',
      code: '+243',
      flag: 'https://flagsapi.com/CD/flat/32.png',
      index: 55,
    },
    {
      name: 'Denmark',
      code: '+45',
      flag: 'https://flagsapi.com/DK/flat/32.png',
      index: 56,
    },
    {
      name: 'Djibouti',
      code: '+253',
      flag: 'https://flagsapi.com/DJ/flat/32.png',
      index: 57,
    },
    {
      name: 'Dominica',
      code: '+1-767',
      flag: 'https://flagsapi.com/DM/flat/32.png',
      index: 58,
    },
    {
      name: 'Dominican Republic',
      code: '+1-809, 1-829, 1-849',
      flag: 'https://flagsapi.com/DO/flat/32.png',
      index: 59,
    },
    {
      name: 'East Timor',
      code: '+670',
      flag: 'https://flagsapi.com/TL/flat/32.png',
      index: 60,
    },
    {
      name: 'Ecuador',
      code: '+593',
      flag: 'https://flagsapi.com/EC/flat/32.png',
      index: 61,
    },
    {
      name: 'Egypt',
      code: '+20',
      flag: 'https://flagsapi.com/EG/flat/32.png',
      index: 62,
    },
    {
      name: 'El Salvador',
      code: '+503',
      flag: 'https://flagsapi.com/SV/flat/32.png',
      index: 63,
    },
    {
      name: 'Equatorial Guinea',
      code: '+240',
      flag: 'https://flagsapi.com/GQ/flat/32.png',
      index: 64,
    },
    {
      name: 'Eritrea',
      code: '+291',
      flag: 'https://flagsapi.com/ER/flat/32.png',
      index: 65,
    },
    {
      name: 'Estonia',
      code: '+372',
      flag: 'https://flagsapi.com/EE/flat/32.png',
      index: 66,
    },
    {
      name: 'Ethiopia',
      code: '+251',
      flag: 'https://flagsapi.com/ET/flat/32.png',
      index: 67,
    },
    {
      name: 'Falkland Islands',
      code: '+500',
      flag: 'https://flagsapi.com/FK/flat/32.png',
      index: 68,
    },
    {
      name: 'Faroe Islands',
      code: '+298',
      flag: 'https://flagsapi.com/FO/flat/32.png',
      index: 69,
    },
    {
      name: 'Fiji',
      code: '+679',
      flag: 'https://flagsapi.com/FJ/flat/32.png',
      index: 70,
    },
    {
      name: 'Finland',
      code: '+358',
      flag: 'https://flagsapi.com/FI/flat/32.png',
      index: 71,
    },
    {
      name: 'France',
      code: '+33',
      flag: 'https://flagsapi.com/FR/flat/32.png',
      index: 72,
    },
    {
      name: 'French Polynesia',
      code: '+689',
      flag: 'https://flagsapi.com/PF/flat/32.png',
      index: 73,
    },
    {
      name: 'Gabon',
      code: '+241',
      flag: 'https://flagsapi.com/GA/flat/32.png',
      index: 74,
    },
    {
      name: 'Gambia',
      code: '+220',
      flag: 'https://flagsapi.com/GM/flat/32.png',
      index: 75,
    },
    {
      name: 'Georgia',
      code: '+995',
      flag: 'https://flagsapi.com/GE/flat/32.png',
      index: 76,
    },
    {
      name: 'Germany',
      code: '+49',
      flag: 'https://flagsapi.com/DE/flat/32.png',
      index: 77,
    },
    {
      name: 'Ghana',
      code: '+233',
      flag: 'https://flagsapi.com/GH/flat/32.png',
      index: 78,
    },
    {
      name: 'Gibraltar',
      code: '+350',
      flag: 'https://flagsapi.com/GI/flat/32.png',
      index: 79,
    },
    {
      name: 'Greece',
      code: '+30',
      flag: 'https://flagsapi.com/GR/flat/32.png',
      index: 80,
    },
    {
      name: 'Greenland',
      code: '+299',
      flag: 'https://flagsapi.com/GL/flat/32.png',
      index: 81,
    },
    {
      name: 'Grenada',
      code: '+1-473',
      flag: 'https://flagsapi.com/GD/flat/32.png',
      index: 82,
    },
    {
      name: 'Guam',
      code: '+1-671',
      flag: 'https://flagsapi.com/GU/flat/32.png',
      index: 83,
    },
    {
      name: 'Guatemala',
      code: '+502',
      flag: 'https://flagsapi.com/GT/flat/32.png',
      index: 84,
    },
    {
      name: 'Guernsey',
      code: '+44-1481',
      flag: 'https://flagsapi.com/GG/flat/32.png',
      index: 85,
    },
    {
      name: 'Guinea',
      code: '+224',
      flag: 'https://flagsapi.com/GN/flat/32.png',
      index: 86,
    },
    {
      name: 'Guinea-Bissau',
      code: '+245',
      flag: 'https://flagsapi.com/GW/flat/32.png',
      index: 87,
    },
    {
      name: 'Guyana',
      code: '+592',
      flag: 'https://flagsapi.com/GY/flat/32.png',
      index: 88,
    },
    {
      name: 'Haiti',
      code: '+509',
      flag: 'https://flagsapi.com/HT/flat/32.png',
      index: 89,
    },
    {
      name: 'Honduras',
      code: '+504',
      flag: 'https://flagsapi.com/HN/flat/32.png',
      index: 90,
    },
    {
      name: 'Hong Kong',
      code: '+852',
      flag: 'https://flagsapi.com/HK/flat/32.png',
      index: 91,
    },
    {
      name: 'Hungary',
      code: '+36',
      flag: 'https://flagsapi.com/HU/flat/32.png',
      index: 92,
    },
    {
      name: 'Iceland',
      code: '+354',
      flag: 'https://flagsapi.com/IS/flat/32.png',
      index: 93,
    },
    {
      name: 'India',
      code: '+91',
      flag: 'https://flagsapi.com/IN/flat/32.png',
      index: 94,
    },
    {
      name: 'Indonesia',
      code: '+62',
      flag: 'https://flagsapi.com/ID/flat/32.png',
      index: 95,
    },
    {
      name: 'Iran',
      code: '+98',
      flag: 'https://flagsapi.com/IR/flat/32.png',
      index: 96,
    },
    {
      name: 'Iraq',
      code: '+964',
      flag: 'https://flagsapi.com/IQ/flat/32.png',
      index: 97,
    },
    {
      name: 'Ireland',
      code: '+353',
      flag: 'https://flagsapi.com/IE/flat/32.png',
      index: 98,
    },
    {
      name: 'Isle of Man',
      code: '+44-1624',
      flag: 'https://flagsapi.com/IM/flat/32.png',
      index: 99,
    },
    {
      name: 'Israel',
      code: '+972',
      flag: 'https://flagsapi.com/IL/flat/32.png',
      index: 100,
    },
    {
      name: 'Italy',
      code: '+39',
      flag: 'https://flagsapi.com/IT/flat/32.png',
      index: 101,
    },
    {
      name: 'Ivory Coast',
      code: '+225',
      flag: 'https://flagsapi.com/CI/flat/32.png',
      index: 102,
    },
    {
      name: 'Jamaica',
      code: '+1-876',
      flag: 'https://flagsapi.com/JM/flat/32.png',
      index: 103,
    },
    {
      name: 'Japan',
      code: '+81',
      flag: 'https://flagsapi.com/JP/flat/32.png',
      index: 104,
    },
    {
      name: 'Jersey',
      code: '+44-1534',
      flag: 'https://flagsapi.com/JE/flat/32.png',
      index: 105,
    },
    {
      name: 'Jordan',
      code: '+962',
      flag: 'https://flagsapi.com/JO/flat/32.png',
      index: 106,
    },
    {
      name: 'Kazakhstan',
      code: '+7',
      flag: 'https://flagsapi.com/KZ/flat/32.png',
      index: 107,
    },
    {
      name: 'Kenya',
      code: '+254',
      flag: 'https://flagsapi.com/KE/flat/32.png',
      index: 108,
    },
    {
      name: 'Kiribati',
      code: '+686',
      flag: 'https://flagsapi.com/KI/flat/32.png',
      index: 109,
    },
    {
      name: 'Kosovo',
      code: '+383',
      flag: 'https://flagsapi.com/XK/flat/32.png',
      index: 110,
    },
    {
      name: 'Kuwait',
      code: '+965',
      flag: 'https://flagsapi.com/KW/flat/32.png',
      index: 111,
    },
    {
      name: 'Kyrgyzstan',
      code: '+996',
      flag: 'https://flagsapi.com/KG/flat/32.png',
      index: 112,
    },
    {
      name: 'Laos',
      code: '+856',
      flag: 'https://flagsapi.com/LA/flat/32.png',
      index: 113,
    },
    {
      name: 'Latvia',
      code: '+371',
      flag: 'https://flagsapi.com/LV/flat/32.png',
      index: 114,
    },
    {
      name: 'Lebanon',
      code: '+961',
      flag: 'https://flagsapi.com/LB/flat/32.png',
      index: 115,
    },
    {
      name: 'Lesotho',
      code: '+266',
      flag: 'https://flagsapi.com/LS/flat/32.png',
      index: 116,
    },
    {
      name: 'Liberia',
      code: '+231',
      flag: 'https://flagsapi.com/LR/flat/32.png',
      index: 117,
    },
    {
      name: 'Libya',
      code: '+218',
      flag: 'https://flagsapi.com/LY/flat/32.png',
      index: 118,
    },
    {
      name: 'Liechtenstein',
      code: '+423',
      flag: 'https://flagsapi.com/LI/flat/32.png',
      index: 119,
    },
    {
      name: 'Lithuania',
      code: '+370',
      flag: 'https://flagsapi.com/LT/flat/32.png',
      index: 120,
    },
    {
      name: 'Luxembourg',
      code: '+352',
      flag: 'https://flagsapi.com/LU/flat/32.png',
      index: 121,
    },
    {
      name: 'Macau',
      code: '+853',
      flag: 'https://flagsapi.com/MO/flat/32.png',
      index: 122,
    },
    {
      name: 'Macedonia',
      code: '+389',
      flag: 'https://flagsapi.com/MK/flat/32.png',
      index: 123,
    },
    {
      name: 'Madagascar',
      code: '+261',
      flag: 'https://flagsapi.com/MG/flat/32.png',
      index: 124,
    },
    {
      name: 'Malawi',
      code: '+265',
      flag: 'https://flagsapi.com/MW/flat/32.png',
      index: 125,
    },
    {
      name: 'Malaysia',
      code: '+60',
      flag: 'https://flagsapi.com/MY/flat/32.png',
      index: 126,
    },
    {
      name: 'Maldives',
      code: '+960',
      flag: 'https://flagsapi.com/MV/flat/32.png',
      index: 127,
    },
    {
      name: 'Mali',
      code: '+223',
      flag: 'https://flagsapi.com/ML/flat/32.png',
      index: 128,
    },
    {
      name: 'Malta',
      code: '+356',
      flag: 'https://flagsapi.com/MT/flat/32.png',
      index: 129,
    },
    {
      name: 'Marshall Islands',
      code: '+692',
      flag: 'https://flagsapi.com/MH/flat/32.png',
      index: 130,
    },
    {
      name: 'Mauritania',
      code: '+222',
      flag: 'https://flagsapi.com/MR/flat/32.png',
      index: 131,
    },
    {
      name: 'Mauritius',
      code: '+230',
      flag: 'https://flagsapi.com/MU/flat/32.png',
      index: 132,
    },
    {
      name: 'Mayotte',
      code: '+262',
      flag: 'https://flagsapi.com/YT/flat/32.png',
      index: 133,
    },
    {
      name: 'Mexico',
      code: '+52',
      flag: 'https://flagsapi.com/MX/flat/32.png',
      index: 134,
    },
    {
      name: 'Micronesia',
      code: '+691',
      flag: 'https://flagsapi.com/FM/flat/32.png',
      index: 135,
    },
    {
      name: 'Moldova',
      code: '+373',
      flag: 'https://flagsapi.com/MD/flat/32.png',
      index: 136,
    },
    {
      name: 'Monaco',
      code: '+377',
      flag: 'https://flagsapi.com/MC/flat/32.png',
      index: 137,
    },
    {
      name: 'Mongolia',
      code: '+976',
      flag: 'https://flagsapi.com/MN/flat/32.png',
      index: 138,
    },
    {
      name: 'Montenegro',
      code: '+382',
      flag: 'https://flagsapi.com/ME/flat/32.png',
      index: 139,
    },
    {
      name: 'Montserrat',
      code: '+1-664',
      flag: 'https://flagsapi.com/MS/flat/32.png',
      index: 140,
    },
    {
      name: 'Morocco',
      code: '+212',
      flag: 'https://flagsapi.com/MA/flat/32.png',
      index: 141,
    },
    {
      name: 'Mozambique',
      code: '+258',
      flag: 'https://flagsapi.com/MZ/flat/32.png',
      index: 142,
    },
    {
      name: 'Myanmar',
      code: '+95',
      flag: 'https://flagsapi.com/MM/flat/32.png',
      index: 143,
    },
    {
      name: 'Namibia',
      code: '+264',
      flag: 'https://flagsapi.com/NA/flat/32.png',
      index: 144,
    },
    {
      name: 'Nauru',
      code: '+674',
      flag: 'https://flagsapi.com/NR/flat/32.png',
      index: 145,
    },
    {
      name: 'Nepal',
      code: '+977',
      flag: 'https://flagsapi.com/NP/flat/32.png',
      index: 146,
    },
    {
      name: 'Netherlands',
      code: '+31',
      flag: 'https://flagsapi.com/NL/flat/32.png',
      index: 147,
    },
    {
      name: 'Netherlands Antilles',
      code: '+599',
      flag: 'https://flagsapi.com/AN/flat/32.png',
      index: 148,
    },
    {
      name: 'New Caledonia',
      code: '+687',
      flag: 'https://flagsapi.com/NC/flat/32.png',
      index: 149,
    },
    {
      name: 'New Zealand',
      code: '+64',
      flag: 'https://flagsapi.com/NZ/flat/32.png',
      index: 150,
    },
    {
      name: 'Nicaragua',
      code: '+505',
      flag: 'https://flagsapi.com/NI/flat/32.png',
      index: 151,
    },
    {
      name: 'Niger',
      code: '+227',
      flag: 'https://flagsapi.com/NE/flat/32.png',
      index: 152,
    },
    {
      name: 'Nigeria',
      code: '+234',
      flag: 'https://flagsapi.com/NG/flat/32.png',
      index: 153,
    },
    {
      name: 'Niue',
      code: '+683',
      flag: 'https://flagsapi.com/NU/flat/32.png',
      index: 154,
    },
    {
      name: 'North Korea',
      code: '+850',
      flag: 'https://flagsapi.com/KP/flat/32.png',
      index: 155,
    },
    {
      name: 'Northern Mariana Islands',
      code: '+1-670',
      flag: 'https://flagsapi.com/MP/flat/32.png',
      index: 156,
    },
    {
      name: 'Norway',
      code: '+47',
      flag: 'https://flagsapi.com/NO/flat/32.png',
      index: 157,
    },
    {
      name: 'Oman',
      code: '+968',
      flag: 'https://flagsapi.com/OM/flat/32.png',
      index: 158,
    },
    {
      name: 'Pakistan',
      code: '+92',
      flag: 'https://flagsapi.com/PK/flat/32.png',
      index: 159,
    },
    {
      name: 'Palau',
      code: '+680',
      flag: 'https://flagsapi.com/PW/flat/32.png',
      index: 160,
    },
    {
      name: 'Palestine',
      code: '+970',
      flag: 'https://flagsapi.com/PS/flat/32.png',
      index: 161,
    },
    {
      name: 'Panama',
      code: '+507',
      flag: 'https://flagsapi.com/PA/flat/32.png',
      index: 162,
    },
    {
      name: 'Papua New Guinea',
      code: '+675',
      flag: 'https://flagsapi.com/PG/flat/32.png',
      index: 163,
    },
    {
      name: 'Paraguay',
      code: '+595',
      flag: 'https://flagsapi.com/PY/flat/32.png',
      index: 164,
    },
    {
      name: 'Peru',
      code: '+51',
      flag: 'https://flagsapi.com/PE/flat/32.png',
      index: 165,
    },
    {
      name: 'Philippines',
      code: '+63',
      flag: 'https://flagsapi.com/PH/flat/32.png',
      index: 166,
    },
    {
      name: 'Pitcairn',
      code: '+64',
      flag: 'https://flagsapi.com/PN/flat/32.png',
      index: 167,
    },
    {
      name: 'Poland',
      code: '+48',
      flag: 'https://flagsapi.com/PL/flat/32.png',
      index: 168,
    },
    {
      name: 'Portugal',
      code: '+351',
      flag: 'https://flagsapi.com/PT/flat/32.png',
      index: 169,
    },
    {
      name: 'Puerto Rico',
      code: '+1-787, 1-939',
      flag: 'https://flagsapi.com/PR/flat/32.png',
      index: 170,
    },
    {
      name: 'Qatar',
      code: '+974',
      flag: 'https://flagsapi.com/QA/flat/32.png',
      index: 171,
    },
    {
      name: 'Republic of the Congo',
      code: '+242',
      flag: 'https://flagsapi.com/CG/flat/32.png',
      index: 172,
    },
    {
      name: 'Reunion',
      code: '+262',
      flag: 'https://flagsapi.com/RE/flat/32.png',
      index: 173,
    },
    {
      name: 'Romania',
      code: '+40',
      flag: 'https://flagsapi.com/RO/flat/32.png',
      index: 174,
    },
    {
      name: 'Russia',
      code: '+7',
      flag: 'https://flagsapi.com/RU/flat/32.png',
      index: 175,
    },
    {
      name: 'Rwanda',
      code: '+250',
      flag: 'https://flagsapi.com/RW/flat/32.png',
      index: 176,
    },
    {
      name: 'Saint Barthelemy',
      code: '+590',
      flag: 'https://flagsapi.com/BL/flat/32.png',
      index: 177,
    },
    {
      name: 'Saint Helena',
      code: '+290',
      flag: 'https://flagsapi.com/SH/flat/32.png',
      index: 178,
    },
    {
      name: 'Saint Kitts and Nevis',
      code: '+1-869',
      flag: 'https://flagsapi.com/KN/flat/32.png',
      index: 179,
    },
    {
      name: 'Saint Lucia',
      code: '+1-758',
      flag: 'https://flagsapi.com/LC/flat/32.png',
      index: 180,
    },
    {
      name: 'Saint Martin',
      code: '+590',
      flag: 'https://flagsapi.com/MF/flat/32.png',
      index: 181,
    },
    {
      name: 'Saint Pierre and Miquelon',
      code: '+508',
      flag: 'https://flagsapi.com/PM/flat/32.png',
      index: 182,
    },
    {
      name: 'Saint Vincent and the Grenadines',
      code: '+1-784',
      flag: 'https://flagsapi.com/VC/flat/32.png',
      index: 183,
    },
    {
      name: 'Samoa',
      code: '+685',
      flag: 'https://flagsapi.com/WS/flat/32.png',
      index: 184,
    },
    {
      name: 'San Marino',
      code: '+378',
      flag: 'https://flagsapi.com/SM/flat/32.png',
      index: 185,
    },
    {
      name: 'Sao Tome and Principe',
      code: '+239',
      flag: 'https://flagsapi.com/ST/flat/32.png',
      index: 186,
    },
    {
      name: 'Saudi Arabia',
      code: '+966',
      flag: 'https://flagsapi.com/SA/flat/32.png',
      index: 187,
    },
    {
      name: 'Senegal',
      code: '+221',
      flag: 'https://flagsapi.com/SN/flat/32.png',
      index: 188,
    },
    {
      name: 'Serbia',
      code: '+381',
      flag: 'https://flagsapi.com/RS/flat/32.png',
      index: 189,
    },
    {
      name: 'Seychelles',
      code: '+248',
      flag: 'https://flagsapi.com/SC/flat/32.png',
      index: 190,
    },
    {
      name: 'Sierra Leone',
      code: '+232',
      flag: 'https://flagsapi.com/SL/flat/32.png',
      index: 191,
    },
    {
      name: 'Singapore',
      code: '+65',
      flag: 'https://flagsapi.com/SG/flat/32.png',
      index: 192,
    },
    {
      name: 'Sint Maarten',
      code: '+1-721',
      flag: 'https://flagsapi.com/SX/flat/32.png',
      index: 193,
    },
    {
      name: 'Slovakia',
      code: '+421',
      flag: 'https://flagsapi.com/SK/flat/32.png',
      index: 194,
    },
    {
      name: 'Slovenia',
      code: '+386',
      flag: 'https://flagsapi.com/SI/flat/32.png',
      index: 195,
    },
    {
      name: 'Solomon Islands',
      code: '+677',
      flag: 'https://flagsapi.com/SB/flat/32.png',
      index: 196,
    },
    {
      name: 'Somalia',
      code: '+252',
      flag: 'https://flagsapi.com/SO/flat/32.png',
      index: 197,
    },
    {
      name: 'South Africa',
      code: '+27',
      flag: 'https://flagsapi.com/ZA/flat/32.png',
      index: 198,
    },
    {
      name: 'South Korea',
      code: '+82',
      flag: 'https://flagsapi.com/KR/flat/32.png',
      index: 199,
    },
    {
      name: 'South Sudan',
      code: '+211',
      flag: 'https://flagsapi.com/SS/flat/32.png',
      index: 200,
    },
    {
      name: 'Spain',
      code: '+34',
      flag: 'https://flagsapi.com/ES/flat/32.png',
      index: 201,
    },
    {
      name: 'Sri Lanka',
      code: '+94',
      flag: 'https://flagsapi.com/LK/flat/32.png',
      index: 202,
    },
    {
      name: 'Sudan',
      code: '+249',
      flag: 'https://flagsapi.com/SD/flat/32.png',
      index: 203,
    },
    {
      name: 'Suriname',
      code: '+597',
      flag: 'https://flagsapi.com/SR/flat/32.png',
      index: 204,
    },
    {
      name: 'Svalbard and Jan Mayen',
      code: '+47',
      flag: 'https://flagsapi.com/SJ/flat/32.png',
      index: 205,
    },
    {
      name: 'Swaziland',
      code: '+268',
      flag: 'https://flagsapi.com/SZ/flat/32.png',
      index: 206,
    },
    {
      name: 'Sweden',
      code: '+46',
      flag: 'https://flagsapi.com/SE/flat/32.png',
      index: 207,
    },
    {
      name: 'Switzerland',
      code: '+41',
      flag: 'https://flagsapi.com/CH/flat/32.png',
      index: 208,
    },
    {
      name: 'Syria',
      code: '+963',
      flag: 'https://flagsapi.com/SY/flat/32.png',
      index: 209,
    },
    {
      name: 'Taiwan',
      code: '+886',
      flag: 'https://flagsapi.com/TW/flat/32.png',
      index: 210,
    },
    {
      name: 'Tajikistan',
      code: '+992',
      flag: 'https://flagsapi.com/TJ/flat/32.png',
      index: 211,
    },
    {
      name: 'Tanzania',
      code: '+255',
      flag: 'https://flagsapi.com/TZ/flat/32.png',
      index: 212,
    },
    {
      name: 'Thailand',
      code: '+66',
      flag: 'https://flagsapi.com/TH/flat/32.png',
      index: 213,
    },
    {
      name: 'Togo',
      code: '+228',
      flag: 'https://flagsapi.com/TG/flat/32.png',
      index: 214,
    },
    {
      name: 'Tokelau',
      code: '+690',
      flag: 'https://flagsapi.com/TK/flat/32.png',
      index: 215,
    },
    {
      name: 'Tonga',
      code: '+676',
      flag: 'https://flagsapi.com/TO/flat/32.png',
      index: 216,
    },
    {
      name: 'Trinidad and Tobago',
      code: '+1-868',
      flag: 'https://flagsapi.com/TT/flat/32.png',
      index: 217,
    },
    {
      name: 'Tunisia',
      code: '+216',
      flag: 'https://flagsapi.com/TN/flat/32.png',
      index: 218,
    },
    {
      name: 'Turkey',
      code: '+90',
      flag: 'https://flagsapi.com/TR/flat/32.png',
      index: 219,
    },
    {
      name: 'Turkmenistan',
      code: '+993',
      flag: 'https://flagsapi.com/TM/flat/32.png',
      index: 220,
    },
    {
      name: 'Turks and Caicos Islands',
      code: '+1-649',
      flag: 'https://flagsapi.com/TC/flat/32.png',
      index: 221,
    },
    {
      name: 'Tuvalu',
      code: '+688',
      flag: 'https://flagsapi.com/TV/flat/32.png',
      index: 222,
    },
    {
      name: 'U.S. Virgin Islands',
      code: '+1-340',
      flag: 'https://flagsapi.com/VI/flat/32.png',
      index: 223,
    },
    {
      name: 'Uganda',
      code: '+256',
      flag: 'https://flagsapi.com/UG/flat/32.png',
      index: 224,
    },
    {
      name: 'Ukraine',
      code: '+380',
      flag: 'https://flagsapi.com/UA/flat/32.png',
      index: 225,
    },
    {
      name: 'United Arab Emirates',
      code: '+971',
      flag: 'https://flagsapi.com/AE/flat/32.png',
      index: 226,
    },
    {
      name: 'United Kingdom',
      code: '+44',
      flag: 'https://flagsapi.com/GB/flat/32.png',
      index: 227,
    },
    {
      name: 'United States',
      code: '+1',
      flag: 'https://flagsapi.com/US/flat/32.png',
      index: 228,
    },
    {
      name: 'Uruguay',
      code: '+598',
      flag: 'https://flagsapi.com/UY/flat/32.png',
      index: 229,
    },
    {
      name: 'Uzbekistan',
      code: '+998',
      flag: 'https://flagsapi.com/UZ/flat/32.png',
      index: 230,
    },
    {
      name: 'Vanuatu',
      code: '+678',
      flag: 'https://flagsapi.com/VU/flat/32.png',
      index: 231,
    },
    {
      name: 'Vatican',
      code: '+379',
      flag: 'https://flagsapi.com/VA/flat/32.png',
      index: 232,
    },
    {
      name: 'Venezuela',
      code: '+58',
      flag: 'https://flagsapi.com/VE/flat/32.png',
      index: 233,
    },
    {
      name: 'Vietnam',
      code: '+84',
      flag: 'https://flagsapi.com/VN/flat/32.png',
      index: 234,
    },
    {
      name: 'Wallis and Futuna',
      code: '+681',
      flag: 'https://flagsapi.com/WF/flat/32.png',
      index: 235,
    },
    {
      name: 'Western Sahara',
      code: '+212',
      flag: 'https://flagsapi.com/EH/flat/32.png',
      index: 236,
    },
    {
      name: 'Yemen',
      code: '+967',
      flag: 'https://flagsapi.com/YE/flat/32.png',
      index: 237,
    },
    {
      name: 'Zambia',
      code: '+260',
      flag: 'https://flagsapi.com/ZM/flat/32.png',
      index: 238,
    },
    {
      name: 'Zimbabwe',
      code: '+263',
      flag: 'https://flagsapi.com/ZW/flat/32.png',
      index: 239,
    },
  ],
  el: [
    { name: 'Αφγανιστάν', code: '+93', flag: 'https://flagsapi.com/AF/flat/32.png', index: 0 },
    { name: 'Αλβανία', code: '+355', flag: 'https://flagsapi.com/AL/flat/32.png', index: 1 },
    { name: 'Αλγερία', code: '+213', flag: 'https://flagsapi.com/DZ/flat/32.png', index: 2 },
    { name: 'Αμερικανική Σαμόα', code: '+1-684', flag: 'https://flagsapi.com/AS/flat/32.png', index: 3 },
    { name: 'Ανδόρα', code: '+376', flag: 'https://flagsapi.com/AD/flat/32.png', index: 4 },
    { name: 'Αγκόλα', code: '+244', flag: 'https://flagsapi.com/AO/flat/32.png', index: 5 },
    { name: 'Ανγκουίλα', code: '+1-264', flag: 'https://flagsapi.com/AI/flat/32.png', index: 6 },
    { name: 'Ανταρκτική', code: '+672', flag: 'https://flagsapi.com/AQ/flat/32.png', index: 7 },
    { name: 'Αντίγκουα και Μπαρμπούντα', code: '+1-268', flag: 'https://flagsapi.com/AG/flat/32.png', index: 8 },
    { name: 'Αργεντινή', code: '+54', flag: 'https://flagsapi.com/AR/flat/32.png', index: 9 },
    { name: 'Αρμενία', code: '+374', flag: 'https://flagsapi.com/AM/flat/32.png', index: 10 },
    { name: 'Αρούμπα', code: '+297', flag: 'https://flagsapi.com/AW/flat/32.png', index: 11 },
    { name: 'Αυστραλία', code: '+61', flag: 'https://flagsapi.com/AU/flat/32.png', index: 12 },
    { name: 'Αυστρία', code: '+43', flag: 'https://flagsapi.com/AT/flat/32.png', index: 13 },
    { name: 'Αζερμπαϊτζάν', code: '+994', flag: 'https://flagsapi.com/AZ/flat/32.png', index: 14 },
    { name: 'Μπαχάμες', code: '+1-242', flag: 'https://flagsapi.com/BS/flat/32.png', index: 15 },
    { name: 'Μπαχρέιν', code: '+973', flag: 'https://flagsapi.com/BH/flat/32.png', index: 16 },
    { name: 'Μπαγκλαντές', code: '+880', flag: 'https://flagsapi.com/BD/flat/32.png', index: 17 },
    { name: 'Μπαρμπάντος', code: '+1-246', flag: 'https://flagsapi.com/BB/flat/32.png', index: 18 },
    { name: 'Λευκορωσία', code: '+375', flag: 'https://flagsapi.com/BY/flat/32.png', index: 19 },
    { name: 'Βέλγιο', code: '+32', flag: 'https://flagsapi.com/BE/flat/32.png', index: 20 },
    { name: 'Μπελίζ', code: '+501', flag: 'https://flagsapi.com/BZ/flat/32.png', index: 21 },
    { name: 'Μπενίν', code: '+229', flag: 'https://flagsapi.com/BJ/flat/32.png', index: 22 },
    { name: 'Βερμούδες', code: '+1-441', flag: 'https://flagsapi.com/BM/flat/32.png', index: 23 },
    { name: 'Μπουτάν', code: '+975', flag: 'https://flagsapi.com/BT/flat/32.png', index: 24 },
    { name: 'Βολιβία', code: '+591', flag: 'https://flagsapi.com/BO/flat/32.png', index: 25 },
    { name: 'Βοσνία και Ερζεγοβίνη', code: '+387', flag: 'https://flagsapi.com/BA/flat/32.png', index: 26 },
    { name: 'Μποτσουάνα', code: '+267', flag: 'https://flagsapi.com/BW/flat/32.png', index: 27 },
    { name: 'Βραζιλία', code: '+55', flag: 'https://flagsapi.com/BR/flat/32.png', index: 28 },
    {
      name: 'Βρετανική Επικράτεια Ινδικού Ωκεανού',
      code: '+246',
      flag: 'https://flagsapi.com/IO/flat/32.png',
      index: 29,
    },
    { name: 'Βρετανικές Παρθένοι Νήσοι', code: '+1-284', flag: 'https://flagsapi.com/VG/flat/32.png', index: 30 },
    { name: 'Μπρουνέι', code: '+673', flag: 'https://flagsapi.com/BN/flat/32.png', index: 31 },
    { name: 'Βουλγαρία', code: '+359', flag: 'https://flagsapi.com/BG/flat/32.png', index: 32 },
    { name: 'Μπουρκίνα Φάσο', code: '+226', flag: 'https://flagsapi.com/BF/flat/32.png', index: 33 },
    { name: 'Μπουρούντι', code: '+257', flag: 'https://flagsapi.com/BI/flat/32.png', index: 34 },
    { name: 'Καμπότζη', code: '+855', flag: 'https://flagsapi.com/KH/flat/32.png', index: 35 },
    { name: 'Καμερούν', code: '+237', flag: 'https://flagsapi.com/CM/flat/32.png', index: 36 },
    { name: 'Καναδάς', code: '+1', flag: 'https://flagsapi.com/CA/flat/32.png', index: 37 },
    { name: 'Πράσινο Ακρωτήριο', code: '+238', flag: 'https://flagsapi.com/CV/flat/32.png', index: 38 },
    { name: 'Νήσοι Κέιμαν', code: '+1-345', flag: 'https://flagsapi.com/KY/flat/32.png', index: 39 },
    { name: 'Κεντροαφρικανική Δημοκρατία', code: '+236', flag: 'https://flagsapi.com/CF/flat/32.png', index: 40 },
    { name: 'Τσαντ', code: '+235', flag: 'https://flagsapi.com/TD/flat/32.png', index: 41 },
    { name: 'Χιλή', code: '+56', flag: 'https://flagsapi.com/CL/flat/32.png', index: 42 },
    { name: 'Κίνα', code: '+86', flag: 'https://flagsapi.com/CN/flat/32.png', index: 43 },
    { name: 'Νησί των Χριστουγέννων', code: '+61', flag: 'https://flagsapi.com/CX/flat/32.png', index: 44 },
    { name: 'Νησιά Κόκος', code: '+61', flag: 'https://flagsapi.com/CC/flat/32.png', index: 45 },
    { name: 'Κολομβία', code: '+57', flag: 'https://flagsapi.com/CO/flat/32.png', index: 46 },
    { name: 'Κομόρες', code: '+269', flag: 'https://flagsapi.com/KM/flat/32.png', index: 47 },
    { name: 'Νήσοι Κουκ', code: '+682', flag: 'https://flagsapi.com/CK/flat/32.png', index: 48 },
    { name: 'Κόστα Ρίκα', code: '+506', flag: 'https://flagsapi.com/CR/flat/32.png', index: 49 },
    { name: 'Κροατία', code: '+385', flag: 'https://flagsapi.com/HR/flat/32.png', index: 50 },
    { name: 'Κούβα', code: '+53', flag: 'https://flagsapi.com/CU/flat/32.png', index: 51 },
    { name: 'Κουρασάο', code: '+599', flag: 'https://flagsapi.com/CW/flat/32.png', index: 52 },
    { name: 'Κύπρος', code: '+357', flag: 'https://flagsapi.com/CY/flat/32.png', index: 53 },
    { name: 'Τσεχία', code: '+420', flag: 'https://flagsapi.com/CZ/flat/32.png', index: 54 },
    { name: 'Λαϊκή Δημοκρατία του Κονγκό', code: '+243', flag: 'https://flagsapi.com/CD/flat/32.png', index: 55 },
    { name: 'Δανία', code: '+45', flag: 'https://flagsapi.com/DK/flat/32.png', index: 56 },
    { name: 'Τζιμπουτί', code: '+253', flag: 'https://flagsapi.com/DJ/flat/32.png', index: 57 },
    { name: 'Ντομίνικα', code: '+1-767', flag: 'https://flagsapi.com/DM/flat/32.png', index: 58 },
    {
      name: 'Δομινικανή Δημοκρατία',
      code: '+1-809, 1-829, 1-849',
      flag: 'https://flagsapi.com/DO/flat/32.png',
      index: 59,
    },
    { name: 'Ανατολικό Τιμόρ', code: '+670', flag: 'https://flagsapi.com/TL/flat/32.png', index: 60 },
    { name: 'Ισημερινός', code: '+593', flag: 'https://flagsapi.com/EC/flat/32.png', index: 61 },
    { name: 'Αίγυπτος', code: '+20', flag: 'https://flagsapi.com/EG/flat/32.png', index: 62 },
    { name: 'Ελ Σαλβαδόρ', code: '+503', flag: 'https://flagsapi.com/SV/flat/32.png', index: 63 },
    { name: 'Ισημερινή Γουινέα', code: '+240', flag: 'https://flagsapi.com/GQ/flat/32.png', index: 64 },
    { name: 'Ερυθραία', code: '+291', flag: 'https://flagsapi.com/ER/flat/32.png', index: 65 },
    { name: 'Εσθονία', code: '+372', flag: 'https://flagsapi.com/EE/flat/32.png', index: 66 },
    { name: 'Αιθιοπία', code: '+251', flag: 'https://flagsapi.com/ET/flat/32.png', index: 67 },
    { name: 'Νησιά Φόκλαντ', code: '+500', flag: 'https://flagsapi.com/FK/flat/32.png', index: 68 },
    { name: 'Νήσοι Φερόες', code: '+298', flag: 'https://flagsapi.com/FO/flat/32.png', index: 69 },
    { name: 'Φίτζι', code: '+679', flag: 'https://flagsapi.com/FJ/flat/32.png', index: 70 },
    { name: 'Φινλανδία', code: '+358', flag: 'https://flagsapi.com/FI/flat/32.png', index: 71 },
    { name: 'Γαλλία', code: '+33', flag: 'https://flagsapi.com/FR/flat/32.png', index: 72 },
    { name: 'Γαλλική Πολυνησία', code: '+689', flag: 'https://flagsapi.com/PF/flat/32.png', index: 73 },
    { name: 'Γκαμπόν', code: '+241', flag: 'https://flagsapi.com/GA/flat/32.png', index: 74 },
    { name: 'Γκάμπια', code: '+220', flag: 'https://flagsapi.com/GM/flat/32.png', index: 75 },
    { name: 'Γεωργία', code: '+995', flag: 'https://flagsapi.com/GE/flat/32.png', index: 76 },
    { name: 'Γερμανία', code: '+49', flag: 'https://flagsapi.com/DE/flat/32.png', index: 77 },
    { name: 'Γκάνα', code: '+233', flag: 'https://flagsapi.com/GH/flat/32.png', index: 78 },
    { name: 'Γιβραλτάρ', code: '+350', flag: 'https://flagsapi.com/GI/flat/32.png', index: 79 },
    { name: 'Ελλάδα', code: '+30', flag: 'https://flagsapi.com/GR/flat/32.png', index: 80 },
    { name: 'Γροιλανδία', code: '+299', flag: 'https://flagsapi.com/GL/flat/32.png', index: 81 },
    { name: 'Γρενάδα', code: '+1-473', flag: 'https://flagsapi.com/GD/flat/32.png', index: 82 },
    { name: 'Γκουάμ', code: '+1-671', flag: 'https://flagsapi.com/GU/flat/32.png', index: 83 },
    { name: 'Γουατεμάλα', code: '+502', flag: 'https://flagsapi.com/GT/flat/32.png', index: 84 },
    { name: 'Γκέρνζι', code: '+44-1481', flag: 'https://flagsapi.com/GG/flat/32.png', index: 85 },
    { name: 'Γουινέα', code: '+224', flag: 'https://flagsapi.com/GN/flat/32.png', index: 86 },
    { name: 'Γουινέα-Μπισσάου', code: '+245', flag: 'https://flagsapi.com/GW/flat/32.png', index: 87 },
    { name: 'Γουιάνα', code: '+592', flag: 'https://flagsapi.com/GY/flat/32.png', index: 88 },
    { name: 'Αϊτή', code: '+509', flag: 'https://flagsapi.com/HT/flat/32.png', index: 89 },
    { name: 'Ονδούρα', code: '+504', flag: 'https://flagsapi.com/HN/flat/32.png', index: 90 },
    { name: 'Χονγκ Κονγκ', code: '+852', flag: 'https://flagsapi.com/HK/flat/32.png', index: 91 },
    { name: 'Ουγγαρία', code: '+36', flag: 'https://flagsapi.com/HU/flat/32.png', index: 92 },
    { name: 'Ισλανδία', code: '+354', flag: 'https://flagsapi.com/IS/flat/32.png', index: 93 },
    { name: 'Ινδία', code: '+91', flag: 'https://flagsapi.com/IN/flat/32.png', index: 94 },
    { name: 'Ινδονησία', code: '+62', flag: 'https://flagsapi.com/ID/flat/32.png', index: 95 },
    { name: 'Ιράν', code: '+98', flag: 'https://flagsapi.com/IR/flat/32.png', index: 96 },
    { name: 'Ιράκ', code: '+964', flag: 'https://flagsapi.com/IQ/flat/32.png', index: 97 },
    { name: 'Ιρλανδία', code: '+353', flag: 'https://flagsapi.com/IE/flat/32.png', index: 98 },
    { name: 'Νήσος του Μαν', code: '+44-1624', flag: 'https://flagsapi.com/IM/flat/32.png', index: 99 },
    { name: 'Ισραήλ', code: '+972', flag: 'https://flagsapi.com/IL/flat/32.png', index: 100 },
    { name: 'Ιταλία', code: '+39', flag: 'https://flagsapi.com/IT/flat/32.png', index: 101 },
    { name: 'Ακτή Ελεφαντοστού', code: '+225', flag: 'https://flagsapi.com/CI/flat/32.png', index: 102 },
    { name: 'Τζαμάικα', code: '+1-876', flag: 'https://flagsapi.com/JM/flat/32.png', index: 103 },
    { name: 'Ιαπωνία', code: '+81', flag: 'https://flagsapi.com/JP/flat/32.png', index: 104 },
    { name: 'Τζέρσεϊ', code: '+44-1534', flag: 'https://flagsapi.com/JE/flat/32.png', index: 105 },
    { name: 'Ιορδανία', code: '+962', flag: 'https://flagsapi.com/JO/flat/32.png', index: 106 },
    { name: 'Καζακστάν', code: '+7', flag: 'https://flagsapi.com/KZ/flat/32.png', index: 107 },
    { name: 'Κένυα', code: '+254', flag: 'https://flagsapi.com/KE/flat/32.png', index: 108 },
    { name: 'Κιριμπάτι', code: '+686', flag: 'https://flagsapi.com/KI/flat/32.png', index: 109 },
    { name: 'Κόσοβο', code: '+383', flag: 'https://flagsapi.com/XK/flat/32.png', index: 110 },
    { name: 'Κουβέιτ', code: '+965', flag: 'https://flagsapi.com/KW/flat/32.png', index: 111 },
    { name: 'Κιργιζία', code: '+996', flag: 'https://flagsapi.com/KG/flat/32.png', index: 112 },
    { name: 'Λάος', code: '+856', flag: 'https://flagsapi.com/LA/flat/32.png', index: 113 },
    { name: 'Λετονία', code: '+371', flag: 'https://flagsapi.com/LV/flat/32.png', index: 114 },
    { name: 'Λίβανος', code: '+961', flag: 'https://flagsapi.com/LB/flat/32.png', index: 115 },
    { name: 'Λεσότο', code: '+266', flag: 'https://flagsapi.com/LS/flat/32.png', index: 116 },
    { name: 'Λιβερία', code: '+231', flag: 'https://flagsapi.com/LR/flat/32.png', index: 117 },
    { name: 'Λιβύη', code: '+218', flag: 'https://flagsapi.com/LY/flat/32.png', index: 118 },
    { name: 'Λιχτενστάιν', code: '+423', flag: 'https://flagsapi.com/LI/flat/32.png', index: 119 },
    { name: 'Λιθουανία', code: '+370', flag: 'https://flagsapi.com/LT/flat/32.png', index: 120 },
    { name: 'Λουξεμβούργο', code: '+352', flag: 'https://flagsapi.com/LU/flat/32.png', index: 121 },
    { name: 'Μακάο', code: '+853', flag: 'https://flagsapi.com/MO/flat/32.png', index: 122 },
    { name: 'Βόρεια Μακεδονία', code: '+389', flag: 'https://flagsapi.com/MK/flat/32.png', index: 123 },
    { name: 'Μαδαγασκάρη', code: '+261', flag: 'https://flagsapi.com/MG/flat/32.png', index: 124 },
    { name: 'Μαλάουι', code: '+265', flag: 'https://flagsapi.com/MW/flat/32.png', index: 125 },
    { name: 'Μαλαισία', code: '+60', flag: 'https://flagsapi.com/MY/flat/32.png', index: 126 },
    { name: 'Μαλδίβες', code: '+960', flag: 'https://flagsapi.com/MV/flat/32.png', index: 127 },
    { name: 'Μάλι', code: '+223', flag: 'https://flagsapi.com/ML/flat/32.png', index: 128 },
    { name: 'Μάλτα', code: '+356', flag: 'https://flagsapi.com/MT/flat/32.png', index: 129 },
    { name: 'Νήσοι Μάρσαλ', code: '+692', flag: 'https://flagsapi.com/MH/flat/32.png', index: 130 },
    { name: 'Μαυριτανία', code: '+222', flag: 'https://flagsapi.com/MR/flat/32.png', index: 131 },
    { name: 'Μαυρίκιος', code: '+230', flag: 'https://flagsapi.com/MU/flat/32.png', index: 132 },
    { name: 'Μαγιότ', code: '+262', flag: 'https://flagsapi.com/YT/flat/32.png', index: 133 },
    { name: 'Μεξικό', code: '+52', flag: 'https://flagsapi.com/MX/flat/32.png', index: 134 },
    { name: 'Μικρονησία', code: '+691', flag: 'https://flagsapi.com/FM/flat/32.png', index: 135 },
    { name: 'Μολδαβία', code: '+373', flag: 'https://flagsapi.com/MD/flat/32.png', index: 136 },
    { name: 'Μονακό', code: '+377', flag: 'https://flagsapi.com/MC/flat/32.png', index: 137 },
    { name: 'Μογγολία', code: '+976', flag: 'https://flagsapi.com/MN/flat/32.png', index: 138 },
    { name: 'Μαυροβούνιο', code: '+382', flag: 'https://flagsapi.com/ME/flat/32.png', index: 139 },
    { name: 'Μονσεράτ', code: '+1-664', flag: 'https://flagsapi.com/MS/flat/32.png', index: 140 },
    { name: 'Μαρόκο', code: '+212', flag: 'https://flagsapi.com/MA/flat/32.png', index: 141 },
    { name: 'Μοζαμβίκη', code: '+258', flag: 'https://flagsapi.com/MZ/flat/32.png', index: 142 },
    { name: 'Μιανμάρ', code: '+95', flag: 'https://flagsapi.com/MM/flat/32.png', index: 143 },
    { name: 'Ναμίμπια', code: '+264', flag: 'https://flagsapi.com/NA/flat/32.png', index: 144 },
    { name: 'Ναουρού', code: '+674', flag: 'https://flagsapi.com/NR/flat/32.png', index: 145 },
    { name: 'Νεπάλ', code: '+977', flag: 'https://flagsapi.com/NP/flat/32.png', index: 146 },
    { name: 'Ολλανδία', code: '+31', flag: 'https://flagsapi.com/NL/flat/32.png', index: 147 },
    { name: 'Ολλανδικές Αντίλλες', code: '+599', flag: 'https://flagsapi.com/AN/flat/32.png', index: 148 },
    { name: 'Νέα Καληδονία', code: '+687', flag: 'https://flagsapi.com/NC/flat/32.png', index: 149 },
    { name: 'Νέα Ζηλανδία', code: '+64', flag: 'https://flagsapi.com/NZ/flat/32.png', index: 150 },
    { name: 'Νικαράγουα', code: '+505', flag: 'https://flagsapi.com/NI/flat/32.png', index: 151 },
    { name: 'Νίγηρας', code: '+227', flag: 'https://flagsapi.com/NE/flat/32.png', index: 152 },
    { name: 'Νιγηρία', code: '+234', flag: 'https://flagsapi.com/NG/flat/32.png', index: 153 },
    { name: 'Νιούε', code: '+683', flag: 'https://flagsapi.com/NU/flat/32.png', index: 154 },
    { name: 'Βόρεια Κορέα', code: '+850', flag: 'https://flagsapi.com/KP/flat/32.png', index: 155 },
    { name: 'Βόρεια Μαριάνα Νησιά', code: '+1-670', flag: 'https://flagsapi.com/MP/flat/32.png', index: 156 },
    { name: 'Νορβηγία', code: '+47', flag: 'https://flagsapi.com/NO/flat/32.png', index: 157 },
    { name: 'Ομάν', code: '+968', flag: 'https://flagsapi.com/OM/flat/32.png', index: 158 },
    { name: 'Πακιστάν', code: '+92', flag: 'https://flagsapi.com/PK/flat/32.png', index: 159 },
    { name: 'Παλάου', code: '+680', flag: 'https://flagsapi.com/PW/flat/32.png', index: 160 },
    { name: 'Παλαιστίνη', code: '+970', flag: 'https://flagsapi.com/PS/flat/32.png', index: 161 },
    { name: 'Παναμάς', code: '+507', flag: 'https://flagsapi.com/PA/flat/32.png', index: 162 },
    { name: 'Παπούα Νέα Γουινέα', code: '+675', flag: 'https://flagsapi.com/PG/flat/32.png', index: 163 },
    { name: 'Παραγουάη', code: '+595', flag: 'https://flagsapi.com/PY/flat/32.png', index: 164 },
    { name: 'Περού', code: '+51', flag: 'https://flagsapi.com/PE/flat/32.png', index: 165 },
    { name: 'Φιλιππίνες', code: '+63', flag: 'https://flagsapi.com/PH/flat/32.png', index: 166 },
    { name: 'Πιτκερν', code: '+64', flag: 'https://flagsapi.com/PN/flat/32.png', index: 167 },
    { name: 'Πολωνία', code: '+48', flag: 'https://flagsapi.com/PL/flat/32.png', index: 168 },
    { name: 'Πορτογαλία', code: '+351', flag: 'https://flagsapi.com/PT/flat/32.png', index: 169 },
    { name: 'Πουέρτο Ρίκο', code: '+1-787, 1-939', flag: 'https://flagsapi.com/PR/flat/32.png', index: 170 },
    { name: 'Κατάρ', code: '+974', flag: 'https://flagsapi.com/QA/flat/32.png', index: 171 },
    { name: 'Δημοκρατία του Κονγκό', code: '+242', flag: 'https://flagsapi.com/CG/flat/32.png', index: 172 },
    { name: 'Ρεϊνιόν', code: '+262', flag: 'https://flagsapi.com/RE/flat/32.png', index: 173 },
    { name: 'Ρουμανία', code: '+40', flag: 'https://flagsapi.com/RO/flat/32.png', index: 174 },
    { name: 'Ρωσία', code: '+7', flag: 'https://flagsapi.com/RU/flat/32.png', index: 175 },
    { name: 'Ρουάντα', code: '+250', flag: 'https://flagsapi.com/RW/flat/32.png', index: 176 },
    { name: 'Άγιος Βαρθολομαίος', code: '+590', flag: 'https://flagsapi.com/BL/flat/32.png', index: 177 },
    { name: 'Άγια Ελένη', code: '+290', flag: 'https://flagsapi.com/SH/flat/32.png', index: 178 },
    { name: 'Άγιος Χριστόφορος και Νέβις', code: '+1-869', flag: 'https://flagsapi.com/KN/flat/32.png', index: 179 },
    { name: 'Αγία Λουκία', code: '+1-758', flag: 'https://flagsapi.com/LC/flat/32.png', index: 180 },
    { name: 'Άγιος Μαρτίνος', code: '+590', flag: 'https://flagsapi.com/MF/flat/32.png', index: 181 },
    { name: 'Άγιος Πέτρος και Μικελόν', code: '+508', flag: 'https://flagsapi.com/PM/flat/32.png', index: 182 },
    { name: 'Άγιος Βικέντιος και Γρεναδίνες', code: '+1-784', flag: 'https://flagsapi.com/VC/flat/32.png', index: 183 },
    { name: 'Σαμόα', code: '+685', flag: 'https://flagsapi.com/WS/flat/32.png', index: 184 },
    { name: 'Άγιος Μαρίνος', code: '+378', flag: 'https://flagsapi.com/SM/flat/32.png', index: 185 },
    { name: 'Σάο Τομέ και Πρίνσιπε', code: '+239', flag: 'https://flagsapi.com/ST/flat/32.png', index: 186 },
    { name: 'Σαουδική Αραβία', code: '+966', flag: 'https://flagsapi.com/SA/flat/32.png', index: 187 },
    { name: 'Σενεγάλη', code: '+221', flag: 'https://flagsapi.com/SN/flat/32.png', index: 188 },
    { name: 'Σερβία', code: '+381', flag: 'https://flagsapi.com/RS/flat/32.png', index: 189 },
    { name: 'Σεϋχέλλες', code: '+248', flag: 'https://flagsapi.com/SC/flat/32.png', index: 190 },
    { name: 'Σιέρα Λεόνε', code: '+232', flag: 'https://flagsapi.com/SL/flat/32.png', index: 191 },
    { name: 'Σιγκαπούρη', code: '+65', flag: 'https://flagsapi.com/SG/flat/32.png', index: 192 },
    { name: 'Σιντ Μαρτίν', code: '+1-721', flag: 'https://flagsapi.com/SX/flat/32.png', index: 193 },
    { name: 'Σλοβακία', code: '+421', flag: 'https://flagsapi.com/SK/flat/32.png', index: 194 },
    { name: 'Σλοβενία', code: '+386', flag: 'https://flagsapi.com/SI/flat/32.png', index: 195 },
    { name: 'Νήσοι Σολομώντα', code: '+677', flag: 'https://flagsapi.com/SB/flat/32.png', index: 196 },
    { name: 'Σομαλία', code: '+252', flag: 'https://flagsapi.com/SO/flat/32.png', index: 197 },
    { name: 'Νότια Αφρική', code: '+27', flag: 'https://flagsapi.com/ZA/flat/32.png', index: 198 },
    { name: 'Νότια Κορέα', code: '+82', flag: 'https://flagsapi.com/KR/flat/32.png', index: 199 },
    { name: 'Νότιο Σουδάν', code: '+211', flag: 'https://flagsapi.com/SS/flat/32.png', index: 200 },
    { name: 'Ισπανία', code: '+34', flag: 'https://flagsapi.com/ES/flat/32.png', index: 201 },
    { name: 'Σρι Λάνκα', code: '+94', flag: 'https://flagsapi.com/LK/flat/32.png', index: 202 },
    { name: 'Σουδάν', code: '+249', flag: 'https://flagsapi.com/SD/flat/32.png', index: 203 },
    { name: 'Σουρινάμ', code: '+597', flag: 'https://flagsapi.com/SR/flat/32.png', index: 204 },
    { name: 'Σβάλμπαρντ και Γιαν Μαγιέν', code: '+47', flag: 'https://flagsapi.com/SJ/flat/32.png', index: 205 },
    { name: 'Σουαζιλάνδη', code: '+268', flag: 'https://flagsapi.com/SZ/flat/32.png', index: 206 },
    { name: 'Σουηδία', code: '+46', flag: 'https://flagsapi.com/SE/flat/32.png', index: 207 },
    { name: 'Ελβετία', code: '+41', flag: 'https://flagsapi.com/CH/flat/32.png', index: 208 },
    { name: 'Συρία', code: '+963', flag: 'https://flagsapi.com/SY/flat/32.png', index: 209 },
    { name: 'Ταϊβάν', code: '+886', flag: 'https://flagsapi.com/TW/flat/32.png', index: 210 },
    { name: 'Τατζικιστάν', code: '+992', flag: 'https://flagsapi.com/TJ/flat/32.png', index: 211 },
    { name: 'Τανζανία', code: '+255', flag: 'https://flagsapi.com/TZ/flat/32.png', index: 212 },
    { name: 'Ταϊλάνδη', code: '+66', flag: 'https://flagsapi.com/TH/flat/32.png', index: 213 },
    { name: 'Τόγκο', code: '+228', flag: 'https://flagsapi.com/TG/flat/32.png', index: 214 },
    { name: 'Τοκελάου', code: '+690', flag: 'https://flagsapi.com/TK/flat/32.png', index: 215 },
    { name: 'Τόνγκα', code: '+676', flag: 'https://flagsapi.com/TO/flat/32.png', index: 216 },
    { name: 'Τρινιντάντ και Τομπάγκο', code: '+1-868', flag: 'https://flagsapi.com/TT/flat/32.png', index: 217 },
    { name: 'Τυνησία', code: '+216', flag: 'https://flagsapi.com/TN/flat/32.png', index: 218 },
    { name: 'Τουρκία', code: '+90', flag: 'https://flagsapi.com/TR/flat/32.png', index: 219 },
    { name: 'Τουρκμενιστάν', code: '+993', flag: 'https://flagsapi.com/TM/flat/32.png', index: 220 },
    { name: 'Νήσοι Τερκς και Κάικος', code: '+1-649', flag: 'https://flagsapi.com/TC/flat/32.png', index: 221 },
    { name: 'Τουβαλού', code: '+688', flag: 'https://flagsapi.com/TV/flat/32.png', index: 222 },
    { name: 'Παρθένοι Νήσοι των ΗΠΑ', code: '+1-340', flag: 'https://flagsapi.com/VI/flat/32.png', index: 223 },
    { name: 'Ουγκάντα', code: '+256', flag: 'https://flagsapi.com/UG/flat/32.png', index: 224 },
    { name: 'Ουκρανία', code: '+380', flag: 'https://flagsapi.com/UA/flat/32.png', index: 225 },
    { name: 'Ηνωμένα Αραβικά Εμιράτα', code: '+971', flag: 'https://flagsapi.com/AE/flat/32.png', index: 226 },
    { name: 'Ηνωμένο Βασίλειο', code: '+44', flag: 'https://flagsapi.com/GB/flat/32.png', index: 227 },
    { name: 'Ηνωμένες Πολιτείες', code: '+1', flag: 'https://flagsapi.com/US/flat/32.png', index: 228 },
    { name: 'Ουρουγουάη', code: '+598', flag: 'https://flagsapi.com/UY/flat/32.png', index: 229 },
    { name: 'Ουζμπεκιστάν', code: '+998', flag: 'https://flagsapi.com/UZ/flat/32.png', index: 230 },
    { name: 'Βανουάτου', code: '+678', flag: 'https://flagsapi.com/VU/flat/32.png', index: 231 },
    { name: 'Βατικανό', code: '+379', flag: 'https://flagsapi.com/VA/flat/32.png', index: 232 },
    { name: 'Βενεζουέλα', code: '+58', flag: 'https://flagsapi.com/VE/flat/32.png', index: 233 },
    { name: 'Βιετνάμ', code: '+84', flag: 'https://flagsapi.com/VN/flat/32.png', index: 234 },
    { name: 'Γουάλις και Φουτουνά', code: '+681', flag: 'https://flagsapi.com/WF/flat/32.png', index: 235 },
    { name: 'Δυτική Σαχάρα', code: '+212', flag: 'https://flagsapi.com/EH/flat/32.png', index: 236 },
    { name: 'Υεμένη', code: '+967', flag: 'https://flagsapi.com/YE/flat/32.png', index: 237 },
    { name: 'Ζάμπια', code: '+260', flag: 'https://flagsapi.com/ZM/flat/32.png', index: 238 },
    { name: 'Ζιμπάμπουε', code: '+263', flag: 'https://flagsapi.com/ZW/flat/32.png', index: 239 },
  ],
};

export function getLocalizedCountries(locale = 'el') {
  return countries[locale];
}
