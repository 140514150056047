import { BehaviorSubject } from 'rxjs';

const CountryCodeSubject = new BehaviorSubject(process.env.countryCode);

export const CountryCodeStore = {
  update: value => {
    CountryCodeSubject.next(value);
  },
  subscribe: setState => CountryCodeSubject.subscribe(setState),
  getValue: () => CountryCodeSubject.value,
};

export default { CountryCodeStore };
