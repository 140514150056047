// function transformCountryList(countryList) {
//   return countryList.map(country => {
//     return {
//       flag: `https://flagsapi.com/${country.codeIso}/flat/32.png`,
//       code: country.code,
//       name: country.nameEl,
//       onClick: () => {},
//     };
//   });
// }
export function includesChar(char, set) {
  if (typeof char !== 'string') return false;
  if (typeof set !== 'string') return false;
  if (char.length !== 1) return false;
  if (set.length < 1) return false;
  return set.indexOf(char) >= 0 ? true : false;
}

export function isNotEmptyString(text) {
  return typeof text === 'string' && text.length > 0;
}
export function sanitizeDiacritics(text) {
  if (!isNotEmptyString(text)) {
    return text;
  }
  let response = '';
  for (let index = 0; index < text.length; index++) {
    if (includesChar(text[index], 'άἀἁἂἃἄἅἆἇὰάᾀᾁᾂᾃᾄᾅᾆᾇᾰᾱᾲᾳᾴᾶᾷ')) {
      response += 'α';
    } else if (includesChar(text[index], 'ΆἈἉἊἋἌἍἎἏᾈᾉᾊᾋᾌᾍᾎᾏᾸᾹᾺΆᾼ')) {
      response += 'Α';
    } else if (includesChar(text[index], 'έἐἑἒἓἔἕὲέ')) {
      response += 'ε';
    } else if (includesChar(text[index], 'ΈἘἙἚἛἜἝ')) {
      response += 'Ε';
    } else if (includesChar(text[index], 'ήἠἡἢἣἤἥἦἧῆὴῇ')) {
      response += 'η';
    } else if (includesChar(text[index], 'ΉἨἩἪἫἬἭἮἯ')) {
      response += 'Η';
    } else if (includesChar(text[index], 'ίἰἱἲἳἴἵὶῖ')) {
      response += 'ι';
    } else if (includesChar(text[index], 'ΊἶἷἸἹἺἻἼἽἾἿ')) {
      response += 'Ι';
    } else if (includesChar(text[index], 'όὀὁὂὃὄὅὸ')) {
      response += 'ο';
    } else if (includesChar(text[index], 'ΌὈὉὊὋὌὍ')) {
      response += 'Ο';
    } else if (includesChar(text[index], 'ύὐὑὒὓὔὕὖὗ')) {
      response += 'υ';
    } else if (includesChar(text[index], 'ΎὙὛὝὟ')) {
      response += 'Υ';
    } else if (includesChar(text[index], 'ώὠὡὢὣὤὥὦὧῶ')) {
      response += 'ω';
    } else if (includesChar(text[index], 'ΏὨὩὪὫὬὭὮὯ')) {
      response += 'Ω';
    } else {
      response += text[index];
    }
  }
  return response;
}

export function searchObjectsByField(objects, field, searchValue) {
  const searchResults = [];
  const lowercaseSearchValue = searchValue.toLowerCase();
  const sanitizedText = sanitizeDiacritics(searchValue);
  for (let i = 0; i < objects.length; i++) {
    const object = objects[i];
    if (
      (object.hasOwnProperty(field) && object[field].toString().toLowerCase().includes(lowercaseSearchValue)) ||
      object[field].toString().toLowerCase().includes(sanitizedText) ||
      sanitizeDiacritics(object[field].toString().toLowerCase()).includes(sanitizedText) ||
      sanitizeDiacritics(object[field].toString()).includes(sanitizedText)
    ) {
      searchResults.push(object);
    }
  }

  return searchResults;
}
export function seperateCountryCode(number) {
  if (number.length >= 10) {
    const countryCode = number.slice(0, number.length - 10);
    const phoneNumber = number.slice(-10);
    return { countryCode, phoneNumber };
  }
  return { phoneNumber: number };
}
