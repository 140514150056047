import { SideSheet, CountryOptionList, SearchBar } from '@desquared/wave-components-library';
import React, { useEffect, useState, useRef } from 'react';
import { CountryCodeSideSheetStore } from '../../store/sideSheet';
import { CountryCodeStore } from '@/store/countryCode';
import { getLocalizedCountries } from './countries';
import styles from './CountryCodeAside.module.css';
import { useTranslations } from 'use-intl';
import { useRouter } from 'next/router';
import { searchObjectsByField } from '@/utils/countriesTransform';

export default function CountryCodeAside() {
  const [sideSheet, setSideSheet] = useState(true);
  const { locale } = useRouter();
  const [searchValue, setSearchValue] = useState('');
  const [searchCountries, setSearchCountries] = useState(getLocalizedCountries(locale));
  const refOne = useRef(null);
  const t = useTranslations('common.asides.country_code');

  const onClick = () => {
    setTimeout(() => {
      CountryCodeSideSheetStore.close();
    }, '300');
  };

  useEffect(() => {
    const CountryCodeSideSheetStore$ = CountryCodeSideSheetStore.subscribe(setSideSheet);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      CountryCodeSideSheetStore$.unsubscribe();
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = e => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      CountryCodeSideSheetStore.close();
    }
  };

  const onSearchChange = value => {
    setSearchValue(value);
    const results = searchObjectsByField(getLocalizedCountries(locale), 'name', value);
    const codeResults = searchObjectsByField(getLocalizedCountries(locale), 'code', value);
    setSearchCountries([...results, ...codeResults]);
  };

  const onItemClick = country => {
    CountryCodeStore.update([country.code]);
    CountryCodeSideSheetStore.close();
  };

  useEffect(() => {
    setSearchCountries(getLocalizedCountries(locale));
  }, [locale]);

  if (!sideSheet) return null;
  return (
    <div ref={refOne}>
      <SideSheet header={t('header')} hasClose={true} onClose={onClick} classOption={styles.countryCodeAsideIndex}>
        <div className={styles.countryCodeAsideContainer}>
          <SearchBar
            isSmall={false}
            isRound={false}
            text={t('search_bar_placeholder')}
            hasContainer={false}
            setValue={onSearchChange}
            value={searchValue}
          />
          <CountryOptionList items={searchCountries} onChange={onItemClick} classOption={styles.scroll} />
        </div>
      </SideSheet>
    </div>
  );
}
